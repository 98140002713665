import * as React from 'react';
import { useEffect } from 'react';

import {
  DarkMode,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  useColorMode,
  useDisclosure,
  useStyleConfig,
} from '@chakra-ui/react';

import { useModalContext } from '../../context/modalContext';

const BottomDrawer: React.FC = ({ children }) => {
  const { colorMode } = useColorMode();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { modal, showNone } = useModalContext();
  const onDrawerClose = () => {
    showNone();
  };
  const drawerStyle = useStyleConfig('DrawerContent', {});

  useEffect(() => {
    if (modal === 'BOTTOM') {
      onOpen();
    } else {
      onClose();
    }
  }, [modal, onClose, onOpen]);

  return (
    <DarkMode>
      <Drawer
        isOpen={isOpen}
        placement="bottom"
        isFullHeight={false}
      // isCentered
        onClose={onDrawerClose}
        closeOnEsc
        size="xs"
        trapFocus={false}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay>
          <DrawerContent bg={'transparent'}>
            <DrawerHeader mt="16" h="4rem" layerStyle={'menu-banner'} p="4px" direction="row">
              <Image w="100%" maxW="12rem" src="/images/beep_collab_inline.png" alt="BeepBus" data-testid="cts-civil-logo" />
              <DrawerCloseButton mt="16" />
            </DrawerHeader>

            <DarkMode>
              <DrawerBody sx={drawerStyle} bg={colorMode === 'light' ? 'coloursUiWhite.500' : 'boxBg.500'}>
                {children}
              </DrawerBody>
            </DarkMode>
          </DrawerContent>
        </DrawerOverlay>

      </Drawer>
    </DarkMode>);
};

export default BottomDrawer;
