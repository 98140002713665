import firebase from 'firebase';
import React from 'react';

export default function useFileUpload() {
  const [selectedFile, setSelectedFile] = React.useState<File|null>(null);
  const [progress, setProgress] = React.useState<number>(0);
  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) setSelectedFile(e.currentTarget.files[0]);
  };
  const uploadFile = (imageFile: File, fileName: string) => {
    if (imageFile) {
      const storageRef = firebase.app().storage().ref(`reports/${fileName}.${imageFile.name.split('.').slice(-1)[0]}`);
      const task = storageRef.put(imageFile);
      task.on('state_changed', (snapshot: any) => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);
        console.log({ percentage });
      });
      return task;
    }
  };
  return { selectedFile, progress, onFileSelected, uploadFile };
}
