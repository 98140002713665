import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  chakra,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { addFeedback } from '../../api/civilAPI';
import { useData } from '../../hooks/useData';

const schema = yup.object().shape({
  email: yup.string().email('Email should have correct format').required('Please enter your email'),
  name: yup.string().required('Please enter your name.'),
  message: yup.string().required('Please enter a message.'),
  category: yup.string().required('Please select a category.'),
});

const FeedbackForm = () => {
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, setValues } = useData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: data.name,
      email: data.email,
      message: data.message,
      category: data.category,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const submitFeedback = useMutation(addFeedback, {
    onSuccess: () => {
      toast({
        position: 'bottom',
        title: 'Good Job!',
        description: 'Our team will review your feedback 😇',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setSubmitSuccess(true);
    },
    onError: () => {
      toast({
        position: 'bottom',
        title: 'Good Job!',
        description: 'Uh oh! and error occurred when trying to submit your feedback, please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const onFeedbackSubmit = (formData: any) => {
    console.log({ formData, errors });
    // todo send to firebase /feedback
    submitFeedback.mutate(formData);
  };


  return (
    <Box p={4}>
      <Center my="2">
        <Heading as="h3" size="md" justifyContent="center">Let’s hear from you! Tell us what you think, share suggestions of new topics or report incorrect information.</Heading>
      </Center>
      { submitSuccess ? <Text> Thank you for your feedback! </Text> :
      <form onSubmit={handleSubmit(onFeedbackSubmit)}>
        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>Name:</FormLabel>
          <Input name="name" ref={register} type="text" value={data.name} />
          <FormErrorMessage>{errors?.name?.message} </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email:</FormLabel>
          <Input name="email" ref={register} type="text" value={data.email} />
          <FormErrorMessage>{errors?.email?.message} </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors?.message}>
          <FormLabel>Message:</FormLabel>
          <Input name="message" ref={register} type="text" value={data.message} />
          <FormErrorMessage>{errors?.message?.message} </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.category} mb="1">
          <FormLabel>Category:</FormLabel>
          <Select name="category" ref={register} value={data.category}>
            <option ref={register} value={'technical'}> Technical </option>
            <option ref={register} value={'suggestion'}> New Category Suggestion </option>
            <option ref={register} value={'correction'}> Report Incorrect Pin </option>
          </Select>
          <FormErrorMessage>{errors?.category?.message} </FormErrorMessage>
        </FormControl>
        <FormControl>
          <Button type="submit"> Submit </Button>
        </FormControl>
      </form>}
    </Box>
  );
};

export default chakra(FeedbackForm);
