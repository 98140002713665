/* eslint-disable no-alert */
import { Category, Coordinate } from '../types';
import { CENTER_LOCATION, SELECT_LOCATION, SET_CURRENT_LOCATION, SET_WORDS, SHOW_CATEGORY } from '../util/constants';
import createDataContext from './createDataContext';

const civilReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_WORDS:
      return {
        ...state,
        words: action.payload,
      };
    case SHOW_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case CENTER_LOCATION:
      return {
        ...state,
        center: { ...state.center, ...action.payload },
      };
    case SELECT_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: { ...state.currentLocation, ...action.payload },
      };
    default:
      return state;
  }
};

const showCategory = (dispatch: any) => (category: Category) => {
  dispatch({ type: SHOW_CATEGORY, payload: category });
};

const selectLocation = (dispatch: any) => (selectedLocation: Coordinate | null) => {
  dispatch({ type: SELECT_LOCATION, payload: selectedLocation });
};
const centerLocation = (dispatch: any) => (selectedLocation: {
  coord?: Coordinate;
  zoom?: number | [number];
} = { coord: [-59.5432, 13.1939], zoom: [15] }) => {
  console.log({ selectedLocation });
  dispatch({ type: CENTER_LOCATION, payload: selectedLocation });
};

const setWords = (dispatch: any) => (words: string) => {
  dispatch({ type: SET_WORDS, payload: words });
};

const getUserLocation = (options: GeoOptions) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

interface GeoOptions {
  timeout?: number;
  maximumAge?: number;
  enableHighAccuracy?: boolean;
  distanceFilter?: number;
  useSignificantChanges?: boolean;
}

const getLocation = (dispatch: any) => async () => {
  const result = await navigator.permissions.query({ name: 'geolocation' });
  if (result.state === 'granted') {
    const location: any = await getUserLocation({});
    const payload = { lng: location?.coords.longitude, lat: location?.coords.latitude };
    dispatch({ type: SET_CURRENT_LOCATION, payload });
    return payload;
  } else {
    return null;
  }
};

interface CivilActions {
  showCategory: Function;
  selectLocation: Function;
  setWords: Function;
  getLocation: Function;
  centerLocation: Function;
}

interface CivilState {
  category: Category;
  selectedLocation: Coordinate | null;
  words: string;
  currentLocation: Coordinate | null;
  center: {
    coord?: Coordinate;
    zoom?: number | [number];
  };
}

const defaultState: CivilState = {
  category: {
    path: 'burstPipes',
    icon: '/images/location_icon_pipe_v2.png',
    menu_icon: '/images/category_icon_pipe.png',
    title: 'Burst Pipes',
    key: 'PIPE',
    description: 'Location of burst water pipe.',
  },
  selectedLocation: null,
  words: '',
  currentLocation: null,
  center: { coord: [-59.5432, 13.1939], zoom: [15] },
};


const DataContext = createDataContext<CivilActions, CivilState>(
  {
    reducer: civilReducer,
    actions: {
      centerLocation,
      getLocation,
      selectLocation,
      setWords,
      showCategory,
    },
    defaultValue: defaultState,
  },
);

const { useDataContext, useDataState, useDataActions } = DataContext.Hooks;
export const MapProvider = DataContext.Provider;
export const useMapState = useDataState;
export const useMapActions = (): CivilActions => useDataActions();
export const useMapContext = useDataContext;
