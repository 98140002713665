import { useState } from 'react';

export function useData(initialValues = {}) {
  const [data, setData] = useState<any>(initialValues);
  const setValues = (values: any) => {
    setData((prevData: any) => ({
      ...prevData,
      ...values,
    }));
  };
  return { data, setValues };
}
