/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdMyLocation } from 'react-icons/md';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Progress,
  Select,
  Spinner,
  Textarea,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { addInformation } from '../../api/civilAPI';
import { convertTow3w } from '../../api/what3wordsAPI';
import { useMapActions } from '../../context/mapContext';
import { useModalActions } from '../../context/modalContext';
import { useData } from '../../hooks/useData';
import type { Address, Contact, Coordinate, Location, Name, Report, Reporter } from '../../types';
import useCategories from './hooks/useCategories';
import useInformationForm from './hooks/useInformationForm';
import useLocationWords from './hooks/useLocationWords';

const schema = yup.object().shape({
  email: yup.string().email('Email should have correct format').required('Please enter your email'),
  first: yup.string(),
  last: yup.string(),
  landline: yup.string(),
  mobile: yup.string(),
  address1: yup.string(),
  address2: yup.string(),
  address3: yup.string(),
  details: yup.string(),
  category: yup.string().required('Please select a category'),
  words: yup.string().required('Please select a location on the map or enter the what3words address.').matches(/[a-z]+\.[a-z]+\.[a-z]+/, 'Invalid what3words location'),
});

const InformationForm = () => {
  const { showNone } = useModalActions();
  const { categories } = useCategories();


  // Location Words
  const { selectedLocation, customErrors, wordsLoading, words } = useLocationWords();

  // Form Setup
  const { data: formData } = useData({});
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      first: formData.first,
      last: formData.last,
      email: formData.email,
      landline: formData.landline,
      mobile: formData.mobile,
      address1: formData.address1,
      address2: formData.address2,
      address3: formData.address3,
      details: formData.details,
      category: formData.category,
      words,
      photo: formData.photo,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // Form Submit
  const toast = useToast();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const informationMutation = useMutation(addInformation, {
    onSuccess: async (data, variables, context) => {
      console.log({ data, variables, context });
      // eslint-disable-next-line @iceworks/best-practices/recommend-polyfill
      const fileName = data?.toString().split('/').slice(-2).join('');
      const onSubmitSuccess = () => {
        setSubmitting(false);
        toast({
          position: 'bottom',
          title: 'Good Job!',
          description: 'Our team will review your contributions and add to the map or pass to the relevant authorities. Thank you for being a good citizen 😇',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        showNone();
      };

      if (selectedFile && fileName) {
        const upLoadTask = await uploadFile(selectedFile, fileName);
        if (upLoadTask && upLoadTask.state === 'success') {
          setTimeout(onSubmitSuccess, 800);
        }
      } else {
        onSubmitSuccess();
      }
    },
  });

  interface InformationFormFields {
    category: string;
    first: string;
    last: string;
    email: string;
    landline: string;
    mobile: string;
    address1: string;
    address2: string;
    address3: string;
    details: any;
  }


  const onSubmit = async (data: InformationFormFields) => {
    try {
      const { first, last } = data;
      const name: Name = { first, last };
      const { email, landline, mobile } = data;
      const contact: Contact = { email, landline, mobile };
      const reporter: Reporter = { name, contact };
      const { address1, address2, address3 } = data;
      const address: Address = { address1, address2, address3 };
      const location: Location = {
        address,
        what3words: words,
        coordinates: {
          lng: selectedLocation[0], lat: selectedLocation[1],
        },
      };
      const { details } = data;
      const report: Report = {
        location,
        created: Math.round(Date.now() / 1000),
        updated: Math.round(Date.now() / 1000),
        status: 'opened',
        reportedType: data.category,
        details,
      };
      setSubmitting(true);
      if (report && reporter && reporter.contact) {
        const payload = { report, reporter };
        await informationMutation.mutate(payload);
      }
    } catch (FormError) {
      setSubmitting(false);
      toast({
        position: 'bottom',
        title: 'Uh oh!',
        description: 'An error occurred while submitting your information',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Upload
  const { selectedFile, progress, uploadFile, onFileSelected } = useInformationForm();
  const { colorMode } = useColorMode();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={!!errors.category}>
        <FormLabel>Category </FormLabel>
        <Select
          name="category"
          ref={register}
          value={formData.category}
          // bg={'boxBg.500'}
          // textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'}
        >
          {categories !== null && categories.map((cat: any) => <option key={`${cat.key}`} ref={register} value={`${cat.path}`}> {cat.title} </option>)}
        </Select>
        <FormErrorMessage>{errors?.category?.message} </FormErrorMessage>
      </FormControl>
      <FormControl mt={6} isInvalid={!!(errors.words || customErrors?.words)} >
        <FormLabel>Location </FormLabel>
        <InputGroup>
          <Input type="text" placeholder="what.three.words" name="words" ref={register} value={formData.words} />
          <InputRightAddon p="4" bg="transparent"><MyLocation /></InputRightAddon>
        </InputGroup>
        {wordsLoading && <Spinner />}
        <FormErrorMessage>{errors?.words?.message || customErrors?.words} </FormErrorMessage>
      </FormControl>
      <Flex direction={['column', 'column', 'row']}>
        <FormControl mt={6} isInvalid={!!errors.address1} >
          <FormLabel>Address 1 </FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="text" placeholder="Mall 123" name="address1" ref={register} value={formData.address1} />
          <FormErrorMessage>{errors?.address1?.message} </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={!!errors.address2} >
          <FormLabel>Address 2 </FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="text" placeholder="Broad Street" name="address2" ref={register} value={formData.address2} />
          <FormErrorMessage>{errors?.address2?.message} </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={!!errors.address3} >
          <FormLabel>Parish</FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="text" placeholder="Saint Michael" name="address3" ref={register} value={formData.address3} />
          <FormErrorMessage>{errors?.address3?.message} </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex direction={['column', 'column', 'row']}>
        <FormControl mt={6} isInvalid={!!errors.first} >
          <FormLabel>Firstname </FormLabel>
          <Input maxW={['90vw', '90vw', '45vw']} type="text" placeholder="Jane" name="first" ref={register} value={formData.frist} />

          <FormErrorMessage>{errors?.first?.message} </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={!!errors.last} >
          <FormLabel>Lastname </FormLabel>
          <Input maxW={['90vw', '90vw', '45vw']} type="text" placeholder="Done" name="last" ref={register} value={formData.last} />

          <FormErrorMessage>{errors?.last?.message} </FormErrorMessage>
        </FormControl>
      </Flex>
      <Flex direction={['column', 'column', 'row']}>
        <FormControl mt={6} isInvalid={!!errors.email} >
          <FormLabel>Email </FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="email" placeholder="joe.doe@example.com" name="email" ref={register} value={formData.email} />
          <FormErrorMessage>{errors?.email?.message} </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={!!errors.mobile} >
          <FormLabel>Mobile </FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="tel" placeholder="+12465555555" name="mobile" ref={register} value={formData.mobile} />
          <FormErrorMessage>{errors?.mobile?.message} </FormErrorMessage>
        </FormControl>
        <FormControl mt={6} isInvalid={!!errors.landline} >
          <FormLabel>Landline </FormLabel>
          <Input maxW={['90vw', '90vw', '30vw']} type="tel" placeholder="+12465555555" name="landline" ref={register} value={formData.landline} />
          <FormErrorMessage>{errors?.landline?.message} </FormErrorMessage>
        </FormControl>
      </Flex>
      <FormControl mt={6} isInvalid={!!errors.details} >
        <FormLabel>Details </FormLabel>
        <Textarea placeholder="You can add additional details here!" name="details" ref={register} value={formData.details} />
        <FormErrorMessage>{errors?.details?.message} </FormErrorMessage>
      </FormControl>
      <FormControl mt={6} isInvalid={!!errors.photo} >
        <FormLabel>Photo </FormLabel>
        <Input py="1" type="file" placeholder="Upload an photo" name="photo" onChange={onFileSelected} value={formData.photo} />
        <Progress value={progress} my="2" />
        <FormErrorMessage>{errors?.photo?.message} </FormErrorMessage>
      </FormControl>
      <Button colorScheme="blue" width="full" mt={4} type="submit" isLoading={isSubmitting}>
        Submit
      </Button>
    </form>
  );
};
const MyLocation = () => {
  const { getLocation, selectLocation, setWords } = useMapActions();
  const { setWordsLoading } = useLocationWords();
  const toast = useToast();

  const onClick = async () => {
    setWordsLoading(true);
    const location = await getLocation();
    console.log({ location });
    if (!location) {
      toast({
        position: 'bottom',
        title: 'Location Error',
        description: 'Unable to retrieve location. Please ensure location is enabled for this site.',
        status: 'error',
        duration: 2500,
        isClosable: true,
      });
      setWordsLoading(false);
    } else {
      selectLocation(location);
      const coordinate: Coordinate | null = location ? [location.lng, location.lat] : null;
      selectLocation(coordinate);
      if (coordinate) {
        const words = await convertTow3w(coordinate);
        setWords(words);
      }
    }
  };

  return <Box _hover={{ color: 'fillColoursUiPrimary1.500' }} ><MdMyLocation onClick={onClick} /></Box>;
};

export default InformationForm;
