/* eslint-disable max-len */
import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link as RouterLink } from 'react-router-dom';

import { Box, DarkMode, Heading, IconButton, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import PageHeader from '../../components/PageHeader/PageHeader';

const AboutPage = () => {
  return (
    <DarkMode>
      <Box h="100vh">
        <PageHeader>
          <Link as={RouterLink} to="/" alignItems="center" d="flex" p="1rem" _hover={{ textDecoration: 'none' }} color="white">
            <IconButton fontWeight="bold" fontSize="1.25rem" h="1.5rem" w="1.5rem" minW="1.5rem" maxW="1.5rem" justifyContent="flex-start" bg="transparent" _hover={{ background: 'transparent', color: 'black' }} icon={<IoMdArrowBack />} aria-label="back-button" />
            <Text d="inline" fontWeight="bold" _hover={{ textDecoration: 'none' }}>Learn More</Text>
          </Link>
        </PageHeader>
        <Box p="1rem">
          <DarkMode><Heading fontSize="1.25rem">Welcome to BeepCollab!</Heading></DarkMode>
          <Text my="1rem">
            We’re better together, and as citizens we can all do our part to make our country better.
            Waze mapped roads, BeepBus mapped bus routes and with BeepCollab you have a platform to collab with us!
            We’ve started with two main project categories: Burst Water Mains &amp; Fire Hydrants.
            Some time ago we had the great opportunity to chat with some of our local firmen about some of the challenges they face in fighting fires.
            Having a digital footprint of all the hydrants locally was one of the main things that emerged.
            What if they could go to a location and see all the hydrants in the area?
          </Text>
          <Text my="1rem">
            We’ve also listened to the public, we’re a water scarce country and having experienced recent droughts,
            it’s paramount that we conserve this resource.
            So why not be able to plot where these burst water mains are and share that in an open platform.
          </Text>
          <Heading fontSize="1rem">We’ve created BeepCollab to help, so join us and Drop A Pin today.</Heading>
          <Text my="1rem">It’s easy….</Text>
          <UnorderedList ml="2rem">
            <ListItem>Tap or select a location</ListItem>
            <ListItem>Select a category and pin it!</ListItem>
            <ListItem>Then view all the pins by category</ListItem>
          </UnorderedList>
          <Text my="1rem">Let’s get pinning Barbados!!!</Text>
        </Box>
      </Box>
    </DarkMode>);
};

export default AboutPage;
