import 'firebase/database';

import app from 'firebase/app';

const config = {
  apiKey: 'AIzaSyAWR6RCYjL6L-A7u01N3tpqg2r42t9AUts',
  authDomain: 'civil-62bd0.firebaseapp.com',
  databaseURL: 'https://civil-62bd0.firebaseio.com',
  projectId: 'civil-62bd0',
  storageBucket: 'civil-62bd0.appspot.com',
  messagingSenderId: '780324490413',
  appId: '1:780324490413:web:cb8c0dc0670aa198dbca24',
  measurementId: 'G-YH98KG341F',
};

const configuredApp = (async (configurableApp) => {
  if (configurableApp.apps.length === 0) {
    await configurableApp.initializeApp(config);
  }
  return configurableApp;
})(app);

// const db = app.database();
// if (location.hostname === 'localhost' && false) {
//   // Point to the RTDB emulator running on localhost.
//   db.useEmulator('localhost', 9000);
// }


export default configuredApp;
