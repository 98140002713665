import { useWords } from '../../../hooks/useWords';
import useGeolocate from './useGeolocate';
import useMapBoxGl from './useMapBoxGl';

export default function useMapBox() {
  const { MapBox, hasLoaded } = useMapBoxGl();
  const { convertTow3w } = useWords();
  const GeolocateControl = useGeolocate();

  return { GeolocateControl, MapBox, hasLoaded, convertTow3w };
}

