/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TiThMenu } from 'react-icons/ti';

import { Box, Center, Flex, IconButton, Image, useColorMode } from '@chakra-ui/react';

import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import CategoryList from '../../components/CategoryList/CategoryList';
import Map from '../../components/Map/MapBox';
import PageHeader from '../../components/PageHeader/PageHeader';
import Search from '../../components/Search/Search';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useMapActions } from '../../context/mapContext';
import { useModalActions } from '../../context/modalContext';

const MapPage: React.FC = () => {
  const { showSidebar } = useModalActions();
  const { getLocation } = useMapActions();
  const { colorMode } = useColorMode();
  const menuRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    getLocation();
  }, []);

  const onMarkerClick = async () => {
    await showSidebar();
  };

  return (
    <Box textAlign="center" fontSize="xl" bgColor="appMenuBlue.400" >
      <Map />
      {/* <Flex direction="column" sx={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}> */}
      <PageHeader>
        <IconButton
          ref={menuRef}
          aria-label="Show menu"
          data-testid="menu-button"
          bbtype="blue"
          icon={<TiThMenu />}
          variant="solid"
          boxShadow="0px 0px 10px 1px rgba(0, 0, 0, 0.25)"
          fontSize="1.5rem"
          color="niceBlue.500"
          bg={colorMode === 'light' ? 'white' : 'white'}
          isRound
          onClick={onMarkerClick}
        />
        <ColorModeSwitcher justifySelf="flex-end" />
      </PageHeader>
      <Box sx={{ position: 'relative' }}>
        <Search />
      </Box>
      {/* </Flex> */}

      <Sidebar buttonRef={menuRef} ><CategoryList /></Sidebar>
    </Box>);
};

export default MapPage;
