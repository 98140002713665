import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { CSSReset, ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import theme from '../theme/theme';
import { MapProvider } from './mapContext';
import { ModalProvider } from './modalContext';

const queryCache = new QueryClient();
const GlobalProviders: React.FC = ({ children }) => {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryCache}>
          <MapProvider>
            <ModalProvider>
              <CSSReset />
              {children}
            </ModalProvider>
          </MapProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  );
};

export default GlobalProviders;
