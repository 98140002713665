export const SET_WORDS = 'SET_WORDS';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const CENTER_LOCATION = 'CENTER_LOCATION';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SHOW_CATEGORY = 'SHOW_CATEGORY';
export const SHOW_NONE = 'SHOW_NONE';
export const SHOW_SIDE = 'SHOW_SIDE';
export const SHOW_BOTTOM = 'SHOW_BOTTOM';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_DETAILS = 'SET_DETAILS';
export const CLEAR_DETAILS = 'CLEAR_DETAILS';
