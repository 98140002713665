import React, { useCallback, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';

import { Box, Input, List, ListIcon, ListItem, Spinner, Text, useColorMode } from '@chakra-ui/react';

import { placeByID, placesSuggest } from '../../api/placesAPI';
import { convertTow3w } from '../../api/what3wordsAPI';
import { useMapContext } from '../../context/mapContext';
import { Coordinate } from '../../types';

const queryConfig = { staleTime: 60000, retry: 1, refetchAllOnWindowFocus: false };

const Search = () => {
  const [query, setQuery] = useState('');
  const { colorMode } = useColorMode();
  const clearQuery = () => {
    setQuery('');
  };
  // Suggestions
  const { isFetching: suggestLoading, data: suggestData } = useQuery(`suggest-${query}`, placesSuggest(query), { enabled: true, ...queryConfig });

  const onUpdateQuery = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  };

  return (
    <Box w={'calc(100vw - 40px)'} p="1" sx={{ position: 'absolute', top: '0px' }}>
      <Input
        placeholder="Enter a location"
        value={query}
        onChange={onUpdateQuery}
        maxW={'calc(100vw - 152px);'}
        bg={colorMode === 'light' ? 'white.500' : 'boxBg.500'}
        color={colorMode === 'light' ? 'black.500' : 'white.500'}
        _placeholder={colorMode === 'light' ? { color: 'coloursUiDarkGrey.500' } : { color: 'coloursUiLightGrey.500' }}
      />
      {(suggestLoading) ? <Spinner d="block" margin="auto" sx={{ position: 'fixed', top: '50%', left: '50%' }} /> : suggestData
      && <Suggestions predictions={suggestData.predictions} clearQuery={clearQuery} /> }
    </Box>
  );
};

const Suggestions = ({ predictions, clearQuery }: {predictions: any[]; clearQuery: () => void}) => {
  const { colorMode } = useColorMode();
  return (
    <Box bg={colorMode === 'light' ? 'white.500' : 'black.500'} maxW={'calc(100vw - 152px);'} m={'0 auto'}>
      <List bg={colorMode === 'light' ? 'white.500' : 'black.500'}>
        { predictions.filter((c, i) => i < 5).map((prediction) => (
          <Suggestion key={`${prediction.place_id}`} prediction={prediction} clearQuery={clearQuery} />
        )) }
      </List>
    </Box>
  );
};

const Suggestion = ({ prediction, clearQuery }: { prediction: any; clearQuery: () => void }) => {
  const { setWords, selectLocation } = useMapContext();
  const { refetch } = useQuery(`suggest-${prediction.place_id}`, placeByID(prediction.place_id), { enabled: false, ...queryConfig });
  const { colorMode } = useColorMode();
  const onSuggestionPressed = useCallback(
    async () => {
      const { data: { result: { geometry: { location: { lat, lng } } } } } = await refetch();
      const coordinate: Coordinate | null = (lat && lng) ? [lng, lat] : null;
      if (coordinate) {
        clearQuery();
        selectLocation(coordinate);
        const words = await convertTow3w(coordinate);
        setWords(words);
      }
    }
    , [clearQuery, refetch, selectLocation, setWords],
  );
  return (
    <ListItem
      borderColor={colorMode === 'dark' ? 'coloursUiWhite.500' : 'boxBg.500'}
      border="solid"
      borderWidth="1px"
      bgColor={colorMode === 'light' ? 'coloursUiWhite.500' : 'boxBg.500'}
      onClick={() => onSuggestionPressed()}
      textAlign="left"
      d="flex"
      p="4px 2px"
      flexWrap="nowrap"
    >
      <ListIcon as={FaMapMarkerAlt} color="green.500" />
      <Text maxW="100%" textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'} d="inline-block" textAlign="left"> {prediction.description}</Text>
    </ListItem>
  );
};

export default Search;
