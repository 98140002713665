import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import { Link as RouterLink } from 'react-router-dom';

import { Box, DarkMode, IconButton, Link, Text } from '@chakra-ui/react';

import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import PageHeader from '../../components/PageHeader/PageHeader';

const FeedbackPage = () => {
  return (
    <DarkMode>
      <Box h="100vh">
        <PageHeader>
          <Link as={RouterLink} to="/" alignItems="center" d="flex" p="1rem" _hover={{ textDecoration: 'none' }} color="white">
            <IconButton fontWeight="bold" fontSize="1.25rem" h="1.5rem" w="1.5rem" minW="1.5rem" maxW="1.5rem" justifyContent="flex-start" bg="transparent" _hover={{ background: 'transparent', color: 'black' }} icon={<IoMdArrowBack />} aria-label="back-button" />
            <Text d="inline" fontWeight="bold" _hover={{ textDecoration: 'none' }}>Feedback</Text>
          </Link>
        </PageHeader>
        <FeedbackForm />
      </Box>
    </DarkMode>);
};

export default FeedbackPage;
