import * as React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Link,
  Tag,
  TagLabel,
  Text,
  useColorMode,
  useDisclosure,
  useStyleConfig,
} from '@chakra-ui/react';

import { useModalContext } from '../../context/modalContext';

interface SidebarProps {
  buttonRef: React.RefObject<HTMLButtonElement>;
}
const Sidebar: React.FC<SidebarProps> = ({ children, buttonRef }) => {
  const { colorMode } = useColorMode();
  const { modal, showNone, details } = useModalContext();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const drawerStyle = useStyleConfig('DrawerContent', {});

  useEffect(() => {
    if (modal === 'SIDE') {
      onOpen();
    } else {
      onClose();
    }
  }, [modal, onClose, onOpen]);

  const onDrawerClose = () => {
    onClose();
    showNone();
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onDrawerClose}
        finalFocusRef={buttonRef}
        closeOnEsc
        size="xs"
      >
        <DrawerOverlay>
          <DrawerContent sx={drawerStyle}>
            {/* <DrawerContent bg={colorMode === 'light' ? 'gray.800' : 'niceBlue.500'}> */}
            <DrawerCloseButton />
            <DrawerHeader h="4rem" p="0" direction="row" layerStyle={'menu-banner'}>
              <Image w="100%" maxW="6rem" src="/images/beep_collab_stack.png" alt="Civil Social" data-testid="cts-civil-logo" />
            </DrawerHeader>

            <DrawerBody px={0}>
              {children}
            </DrawerBody>

            <DrawerFooter alignItems="flex-start" alignContent="flex-start" justifyContent="flex-start" px="0">
              <Flex direction="column" w="100%">
                {details
              &&
              <Box mx="1rem" py="0.5rem" borderColor="coloursUiBaseHover.500">
                {details.status && <Text py="0.5rem" textStyle={'info'}>Status: <Tag textStyle={'info'}>{details.status}</Tag></Text>}
                <Text py="0.5rem" textStyle={'info'}>Location: <Tag textStyle={'info'}><TagLabel>{`///${details.words}`}</TagLabel></Tag></Text>
              </Box>
              }
                <Link
                  py="0.5rem"
                  px="1rem"
                  w="100%"
                  _hover={{ background: 'fillColoursUiPrimary1Hover.500' }}
                  as={RouterLink}
                  to="/learn-more"
                >
                  <Text
                    textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'}
                  >Learn More
                  </Text>
                </Link>
                <Link py="0.5rem" px="1rem" w="100%" _hover={{ background: 'fillColoursUiPrimary1Hover.500' }} as={RouterLink} to="/feedback">
                  <Text textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'}>Send Us Feedback</Text>
                </Link>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>);
};

export default Sidebar;
