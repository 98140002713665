import React, { useReducer } from 'react';

interface ContextParams<T, S> {
  reducer: React.Reducer<any, any>;
  actions: T & { [key: string]: Function };
  defaultValue: S & { [key: string]: any };
  hooks?: { [key: string]: Function } | null;
}

interface DataContext<T, S> {
  Provider: React.FC<{}>;
  Actions: React.Context<T|{}>;
  Context: React.Context<S|{}>;
  Hooks: {
    [key: string]: Function;
  };
}


// eslint-disable-next-line max-len
function createDataContext<T, S>({ reducer, actions, defaultValue }: ContextParams<T, S>): DataContext<T, S> {
  const Context = React.createContext<S|{}>({});
  const Actions = React.createContext<T|{}>({});


  function useDataState() {
    const context = React.useContext(Context);
    if (context === undefined) {
      throw new Error('useDataState must be used within a CountProvider');
    }
    return context;
  }

  function useDataActions() {
    const context = React.useContext(Actions);
    if (context === undefined) {
      throw new Error('useDataActions must be used within a CountProvider');
    }
    return context;
  }
  const useDataContext = () => {
    return { ...useDataState(), ...useDataActions() };
  };

  const Hooks = {
    useDataState,
    useDataActions,
    useDataContext,
  };

  // eslint-disable-next-line react/prop-types
  const Provider: React.FC<any> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions: { [key: string]: any } = {};
    // eslint-disable-next-line guard-for-in
    for (const key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={state}>
        <Actions.Provider value={{ ...boundActions }}>
          { children }
        </Actions.Provider>
      </Context.Provider>
    );
  };

  return { Provider, Actions, Context, Hooks };
}

export default createDataContext;
