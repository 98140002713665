

const baseURL = 'https://beepbus-node-middleware.herokuapp.com';
const placeSearch = '/googleplace/placesearch/';
const placeAutocomplete = '/googleplace/autosuggest/';
const placeID = '/googleplace/placeid/';
// const placeReverseGeocode = '/googleplace/reverse_geocode/';


export function placesSearch(param: string) {
  return async () => {
    if (param) {
      const result = await (
        await fetch(
          `${baseURL}${placeSearch}${param}`,
        )).json();
      return result;
    }
  };
}

export function placesSuggest(param: string) {
  return async () => {
    if (param) {
      const result = await (
        await fetch(
          `${baseURL}${placeAutocomplete}${param}`,
        )).json();
      return result;
    }
  };
}
export function placeByID(param: string) {
  return async () => {
    if (param) {
      const result = await (
        await fetch(
          `${baseURL}${placeID}${param}`,
        )).json();
      return result;
    }
  };
}
