import mapboxgl from 'mapbox-gl';
import * as React from 'react';

export default function useMap() {
  const controlAdded = React.useRef(false);
  const GeolocateControl = React.useCallback((map) => {
    if (map && !controlAdded.current) {
      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }));
      controlAdded.current = true;
    }
    return null;
  }, []);

  return GeolocateControl;
}

