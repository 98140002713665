import useFileUpload from './useFileUpload';
import useLocationWords from './useLocationWords';
import useValidation from './useValidation';

export default function useInformationForm() {
  const validation = useValidation();
  const locationWords = useLocationWords();
  const fileUpload = useFileUpload();


  return { ...validation, ...fileUpload, ...locationWords };
}

