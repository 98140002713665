import app from 'firebase/app';

import { Category, Feedback, Layer, Report, Reporter, Suggestion } from '../types';

/**
 * Fetch the GeoJSON data for  a specific category
 * @param category
 */
export function fetchLayer(category: Category) {
  return async () => {
    const snapshot = await app.database().ref(`/layers/${category}`).once('value');
    const categoryData: Layer = snapshot.val();
    return categoryData;
  };
}

/**
 * Fetch the lists of categories
 */
export async function fetchCategoryList(): Promise<Category[]> {
  const snapshot = await app.database().ref('/categories').once('value');
  const categoryList: Category[] = snapshot.val();
  return categoryList;
}

/**
 * Submit a report of issue / information
 * @param report
 * @param reporter
 */
interface InformationRequestParams {report: Report; reporter: Reporter}
export async function addInformation({ report, reporter }: InformationRequestParams) {
  const ref = await app.database().ref('/information').push({ report, reporter });
  return ref;
}

/**
 * Submit a suggestion
 * @param report
 * @param reporter
 */
export async function addSuggestion(suggestion: Suggestion, reporter: Reporter) {
  const ref = await app.database().ref('/suggestionCategories').push({ category: suggestion, reporter });
  return ref;
}

/**
 * Submit Feedback
 * @param feedback: Feedback
 */
export async function addFeedback(feedback: Feedback) {
  const ref = await app.database().ref('/feedback').push(feedback);
  return ref;
}
