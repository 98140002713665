import React from 'react';

import { useWords } from '../../../hooks/useWords';

export default function useAddItem() {
  const [errors, setErrors] = React.useState<string | null>(null);
  const [category, setCategory] = React.useState<string>('burstPipe');
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const { convertToCoordinates } = useWords();
  return { convertToCoordinates, errors, setErrors, category, setCategory, isLoading, setLoading };
}
