import React from 'react';
import ReactMapboxGl from 'react-mapbox-gl';

import config from '../../../util/config';

const { AccessTokens } = config;
const TOKEN = AccessTokens.mapboxApiKey;

const MapBox = ReactMapboxGl({
  accessToken:
        TOKEN,
  attributionControl: false,
  logoPosition: 'bottom-right',
});

export default function useMapBoxGL() {
  const [hasLoaded, setHasLoaded] = React.useState(false);


  React.useEffect(() => {
    if (MapBox) {
      setHasLoaded(true);
    }
  }, [setHasLoaded]);
  return { MapBox, hasLoaded };
}
