import { CLEAR_DETAILS, SET_DETAILS, SHOW_BOTTOM, SHOW_MODAL, SHOW_NONE, SHOW_SIDE } from '../util/constants';
import createDataContext from './createDataContext';

const civilReducer = (state: any, action: any) => {
  switch (action.type) {
    case SHOW_NONE:
      return {
        ...state,
        modal: 'NONE',
      };
    case SHOW_SIDE:
      return {
        ...state,
        modal: 'SIDE',
      };
    case SHOW_BOTTOM:
      return {
        ...state,
        modal: 'BOTTOM',
      };
    case SHOW_MODAL:
      return {
        ...state,
        modal: 'MODAL',
      };
    case SET_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case CLEAR_DETAILS:
      return {
        ...state,
        details: null,
      };
    default:
      return state;
  }
};

const showModal = (dispatch: any) => () => {
  dispatch({ type: SHOW_MODAL });
};
const showBottomDrawer = (dispatch: any) => () => {
  dispatch({ type: SHOW_BOTTOM });
};
const showSidebar = (dispatch: any) => () => {
  dispatch({ type: SHOW_SIDE });
};
const showNone = (dispatch: any) => () => {
  dispatch({ type: SHOW_NONE });
};
const clearDetails = (dispatch: any) => () => {
  dispatch({ type: CLEAR_DETAILS });
};
const setDetails = (dispatch: any) => (payload: any) => {
  dispatch({ type: SET_DETAILS, payload });
};

interface ModalState {
  modal: 'NONE' | 'SIDE' | 'BOTTOM' | 'MODAL';
  details: any;
}

interface ModalActions {
  showModal: Function;
  showBottomDrawer: Function;
  showSidebar: Function;
  showNone: Function;
  clearDetails: Function;
  setDetails: Function;
}

type ModalContext = ModalActions & ModalState;
const defaultState: ModalState = {
  modal: 'NONE',
  details: null,
};


const DataContext = createDataContext<ModalActions, ModalState>(
  {
    reducer: civilReducer,
    actions: {
      showModal,
      showBottomDrawer,
      showSidebar,
      showNone,
      clearDetails,
      setDetails,
    },
    defaultValue: defaultState,
  },
);

const { useDataState, useDataActions } = DataContext.Hooks;

export const ModalProvider = DataContext.Provider;
export const useModalState = (): ModalState => useDataState();
export const useModalActions = (): ModalActions => useDataActions();
export const useModalContext = (): ModalContext => ({ ...useModalState(), ...useModalActions() });
