import { useEffect, useState } from 'react';

import { useMapContext } from '../../../context/mapContext';
import useAddItem from './useAddItem';
import useValidation from './useValidation';

export default function useLocationWords() {
  // Location and what3words
  const { selectedLocation, words, selectLocation } = useMapContext();
  const [customErrors, setFormErrors] = useState<Record<string, string>>();
  const { validateWords } = useValidation();
  const {
    convertToCoordinates,
    setErrors,
    isLoading: wordsLoading,
    setLoading: setWordsLoading } = useAddItem();

  useEffect(() => {
    setWordsLoading(true);
    const validatedWords = validateWords(words);
    if (!validatedWords) {
      convertToCoordinates(words).then((coordinates) => {
        setWordsLoading(false);
        if (coordinates === null) {
          setFormErrors((_errors: any) => {
            const newData = { ..._errors, words: 'Invalid w3w location' };
            return newData;
          });

          return;
        }
        setErrors(null);
        selectLocation(coordinates);
        setWordsLoading(false);
      });
    } else {
      setWordsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words]);

  return { selectedLocation, customErrors, wordsLoading, words, setWordsLoading };
}
