// theme.js
import { ChakraTheme, extendTheme } from '@chakra-ui/react';

const overrides: Partial<ChakraTheme> = {
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  colors: {
    fillPrimary: { 500: '#39c6b4' },
    fillSecondary: { 500: '#94ded1' },
    fillBase: { 500: '#1c3555' },
    fillColoursUiPrimary1: { 500: '#f4b119' },
    fillColoursUiPrimary1Hover: { 500: '#ffd066' },
    coloursUiPrimary2: { 500: '#26aae2' },
    coloursUiPrimary2Hover: { 500: '#6cc9f0' },
    coloursUiSecondary3: { 500: '#d53a33' },
    coloursUiSecondary4: { 500: '#06d6a0' },
    coloursUiBase: { 500: '#0e6784' },
    coloursUiBaseHover: { 500: '#5296ac' },
    coloursUiLightGrey: { 500: '#b2b3b4' },
    coloursUiDarkGrey: { 500: '#7f8081' },
    coloursUiWhite: { 500: '#ffffff' },
    niceBlue: { 500: '#1a7fa5' },
    bluish: { 500: '#299dcc' },
    appMenuBlue: { 400: 'rgb(0,174,238)' }, // 12,174,235
    boxBg: { 500: ' rgb(45,55,72)' },
    aqua:
    {
      50: '#ddfcfa',
      100: '#bdf0e9',
      200: '#98e4da',
      300: '#71d7cb',
      400: '#4dccbc',
      500: '#33b2a2',
      600: '#248b7e',
      700: '#14645a',
      800: '#033c36',
      900: '#001713',
    },
    moss:
    {
      50: '#e2fcf8',
      100: '#c2eee6',
      200: '#9ee1d5',
      300: '#79d5c4',
      400: '#56c8b4',
      500: '#3dae9a',
      600: '#2d8878',
      700: '#1e6156',
      800: '#0c3a33',
      900: '#001511',
    },
    ocean:
    {
      50: '#e7f1ff',
      100: '#c3d6ef',
      200: '#9fbbdf',
      300: '#7aa0d2',
      400: '#5585c3',
      500: '#3d6cab',
      600: '#2e5485',
      700: '#203c60',
      800: '#10243c',
      900: '#000d19',
    },
    sun:

    {
      50: '#fff7dc',
      100: '#fee6b0',
      200: '#fad682',
      300: '#f7c553',
      400: '#f5b524',
      500: '#db9b0a',
      600: '#aa7904',
      700: '#7b5601',
      800: '#4a3400',
      900: '#1c1000',
    },
    sand:

    {
      50: '#fff7db',
      100: '#ffe8af',
      200: '#ffd87f',
      300: '#ffc84d',
      400: '#ffb91d',
      500: '#e69f06',
      600: '#b37c00',
      700: '#805800',
      800: '#4e3500',
      900: '#1d1100',
    },
    sea:
    {
      50: '#ddf8ff',
      100: '#b5e6fa',
      200: '#8bd4f2',
      300: '#5fc1eb',
      400: '#35b0e4',
      500: '#1b96ca',
      600: '#0c759e',
      700: '#005372',
      800: '#003347',
      900: '#00131c',
    },
    pool:
    {
      50: '#ddf8ff',
      100: '#b4e7fb',
      200: '#89d5f4',
      300: '#5dc3ee',
      400: '#35b3e9',
      500: '#1e99cf',
      600: '#1177a2',
      700: '#045575',
      800: '#003448',
      900: '#00131d',
    },
    blood:
    {
      50: '#ffe7e6',
      100: '#f7c0be',
      200: '#eb9894',
      300: '#e26f6a',
      400: '#d84741',
      500: '#be2e27',
      600: '#95231d',
      700: '#6b1715',
      800: '#420c0a',
      900: '#1d0100',
    },
    turquoise:
    {
      50: '#d9fff8',
      100: '#aefee9',
      200: '#7efddc',
      300: '#4dface',
      400: '#20f9c0',
      500: '#06dfa7',
      600: '#00ae82',
      700: '#007c5c',
      800: '#004b37',
      900: '#001b12',
    },
    sky:
    {
      50: '#def9ff',
      100: '#b6e9fa',
      200: '#8cd9f3',
      300: '#61caed',
      400: '#3cbbe7',
      500: '#27a2cd',
      600: '#197ea0',
      700: '#0c5a73',
      800: '#003647',
      900: '#00141b',
    },
    overcast:
    {
      50: '#e1f8fe',
      100: '#c4e1ea',
      200: '#a5ccd8',
      300: '#84b6c6',
      400: '#64a1b5',
      500: '#4a889b',
      600: '#38697a',
      700: '#244b58',
      800: '#102e37',
      900: '#001217',
    },
    gred:
    {
      50: '#fbeff2',
      100: '#d5dada',
      200: '#bebfc0',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#3f4041',
      800: '#262629',
      900: '#150a0d',
    },
    grink:
    {
      50: '#feeef2',
      100: '#d2dbdb',
      200: '#bfbfc2',
      300: '#a3a6a6',
      400: '#8b8c8d',
      500: '#727374',
      600: '#59595b',
      700: '#3c4141',
      800: '#26262b',
      900: '#17090d',
    },
    nlue:
    {
      50: '#def8ff',
      100: '#b8e6f8',
      200: '#8fd4ef',
      300: '#65c3e7',
      400: '#3eb2df',
      500: '#2699c5',
      600: '#18779a',
      700: '#0a556f',
      800: '#003445',
      900: '#00131b',
    },
    blish:
    {
      50: '#ddf8ff',
      100: '#b8e5f6',
      200: '#91d2ec',
      300: '#67bfe2',
      400: '#40add9',
      500: '#2693bf',
      600: '#187296',
      700: '#09526c',
      800: '#003244',
      900: '#00121b',
    },
    amue:
    {
      50: '#d8fbff',
      100: '#abebff',
      200: '#7adeff',
      300: '#48cfff',
      400: '#1ac1ff',
      500: '#00a8e6',
      600: '#0083b4',
      700: '#005d82',
      800: '#003951',
      900: '#001520',
    },


  },
  styles: {

  },
  layerStyles: {
    base: {
      bg: 'gray.50',
      border: '2px solid',
      borderColor: 'gray.500',
    },
    selected: {
      bg: 'teal.500',
      color: 'teal.700',
      borderColor: 'orange.500',
    },
    button: {
      bg: 'fillColoursUiPrimary1.500',
    },
    banner: {
      backgroundImage: 'url("/images/header_bg_circles.jpg")',
      backgroundSize: 'cover',
    },
    'menu-banner': {
      backgroundImage: 'url("/images/header_bg_angles.jpg")',
      backgroundSize: 'cover',
    },
  },
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ['48px', '72px'],
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-2%',
    },
    h2: {
      fontSize: ['36px', '48px'],
      fontWeight: 'semibold',
      lineHeight: '110%',
      letterSpacing: '-1%',
    },
    a: {
      fontSize: ['14px'],
      color: 'rgb(0,174,239)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
    },
    linkLight: {
      fontSize: ['14px'],
      color: 'rgb(0,174,239)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listDark: {
      fontSize: ['14px'],
      color: 'rgb(255,255,255)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listLight: {
      fontSize: ['14px'],
      color: 'rgb(255,255,255)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    linkDark: {
      fontSize: ['14px'],
      color: 'rgb(0,174,239)',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    info: {
      fontSize: ['14px'],
      // color: 'fillBase.500',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 500,
    },
    menuTitle: {
      fontSize: ['20px'],
      color: 'coloursUiDarkGrey.500',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 700,
    },
    icon: {
      fontSize: ['14px'],
      color: 'fillColoursUiPrimary1.500',
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontWeight: 700,
    },
  },
  components: {
    DrawerContent: {
      // baseStyle: (props: GlobalStyleProps) => ({
      //   bg: mode('boxBg.500', 'boxBg.500')(props),
      //   color: mode('coloursUiWhite.500', 'coloursUiWhite.500')(props),
      // }),
    },
  },
};

export default extendTheme(overrides);
