// This file contains all the Application API Keys and Access Tokens

const config = {
  AccessTokens: {
    mapboxApiKey:
      'pk.eyJ1Ijoiam9lbG1vb3JlLWN0cyIsImEiOiJjazBwdThqcm8wMW55M29xbzZnNGdkM3B4In0.9qZoe6CbXNIQh9kIacmLSA',
    googleApiKey: 'AIzaSyBu5GocXsoJWyLr-DluvJx5JQfCbZ6G9nA',
    w3wAPIKEY: 'S5BS9CFA',
    firebase: 'AIzaSyAWR6RCYjL6L-A7u01N3tpqg2r42t9AUts',
    rollbar: '5347571529944ec2b04dfc6cc940dfe2',
  },
};

export default config;
