import React from 'react';

import { Box, Flex, Image } from '@chakra-ui/react';

const PageHeader: React.FC = ({ children }) => {
  return (
    <Flex direction="column">
      <Flex
        px={3}
        py={2}
        layerStyle={'banner'}
        justifyContent="space-between"
      >
        <Box>
          { children }
        </Box>
        <Box>
          <Image src="/images/beep_collab_stack.png" maxW="6rem" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default PageHeader;
