import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Providers from '../../context/GlobalProviders';
import useLogger from '../../hooks/useLogger';
import FeedbackPage from '../../pages/FeedbackPage';
import LearnMore from '../../pages/LearnMore/LearnMore';
import MapPage from '../../pages/MapPage';
import BottomDrawer from '../BottomDrawer/BottomDrawer';
import InformationForm from '../InformationForm/InformationForm';

export const App: React.FC = () => {
  useLogger();
  return (
    <Providers>
      <Router>
        <Switch>
          <Route path="/" exact>
            <MapPage />
          </Route>
          <Route path="/learn-more" exact component={LearnMore} />
          <Route path="/feedback" exact component={FeedbackPage} />
        </Switch>
      </Router>
      <BottomDrawer><InformationForm /></BottomDrawer>
    </Providers>
  );
};
App.whyDidYouRender = true;
export default App;
