import Rollbar from 'rollbar';

import config from '../util/config';

const { AccessTokens } = config;

const rollbar = new Rollbar({
  accessToken: AccessTokens.rollbar,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'development',
  },
});

export default function useRollbar() {
  return [rollbar];
}
