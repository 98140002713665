import * as EmailValidator from 'email-validator';
import parsePhoneNumber from 'libphonenumber-js';

import { Category } from '../../../types';

export default function useValidation() {
  const validateCategory = (cs: Category[] | null) => (v: Category) => (cs && cs.find((c) => c === v) ? false : 'Unrecognized Category');
  const validateWords = (value: string) => {
    if (value) {
      // ensure it matches regex
      const w3wRE = /[a-z]+\.[a-z]+\.[a-z]+/;
      const matches = value.match(w3wRE);
      return (matches && matches.length > 0) ? false : 'Invalid what3words location';
    }
    return 'Invalid what3words location';
  };
  const validatePhone = (value: string) => {
    try {
      const phoneNumber = parsePhoneNumber(value);
      return phoneNumber?.isValid() || value === '' ? false : 'Phone number must be valid';
    } catch (e) {
      return e.message;
    }
  };
  const validateEmail = (value: string) => {
    return EmailValidator.validate(value) || value === '' ? false : 'Please use a valid email address';
  };
  const validation: Record<string, Function> = {
    words: validateWords,
    category: validateCategory,
    mobile: validatePhone,
    landline: validatePhone,
    email: validateEmail,
    first: () => false,
    last: () => false,
    details: () => false,
    address1: () => false,
    address2: () => false,
    address3: () => false,
  };

  return { validation, validateCategory, validateWords, validatePhone, validateEmail };
}
