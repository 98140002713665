import { Coordinate, ErrorResponse, W3wResponse } from '../types';
import config from '../util/config';

const { w3wAPIKEY } = config.AccessTokens;


export function useWords() {
  async function convertTow3w(location: [number, number]) {
    const coordinates = `${location[1]}%2C${location[0]}`;
    const response = await fetch(`https://api.what3words.com/v3/convert-to-3wa?coordinates=${coordinates}&key=${w3wAPIKEY}`);
    const w3wResponse: ErrorResponse | W3wResponse = await response.json();
    if ('words' in w3wResponse) {
      return w3wResponse.words;
    }
  }

  const convertToCoordinates = async (inputWords: string) => {
    const response = await fetch(`https://api.what3words.com/v3/convert-to-coordinates?words=${inputWords}&key=${w3wAPIKEY}`);
    const w3wResponse: ErrorResponse | W3wResponse = await response.json();

    if ('error' in w3wResponse) {
      console.error(w3wResponse.error.message);
      return null;
    }
    const coordinate: Coordinate = [w3wResponse.coordinates.lng, w3wResponse.coordinates.lat];
    return coordinate;
  };
  return { convertTow3w, convertToCoordinates };
}

