import * as React from 'react';
import { useState } from 'react';
// import { FaExclamationCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Image,
  Link,
  ListItem,
  ScaleFade,
  Spinner,
  Text,
  UnorderedList,
  useColorMode,
} from '@chakra-ui/react';

import { fetchCategoryList } from '../../api/civilAPI';
import { useMapActions } from '../../context/mapContext';
import { useModalActions } from '../../context/modalContext';
import { Category } from '../../types';

interface CategoryListProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

const CategoryList: React.FC<CategoryListProps> = () => {
  const [categories, setCategories] = useState<Category[]|null>(null);
  const { showBottomDrawer } = useModalActions();
  const { isLoading, data } = useQuery('category-list', fetchCategoryList);
  const { colorMode } = useColorMode();
  React.useEffect(() => {
    const values = [];
    if (data) {
      for (const key in data) {
        if (key) {
          values.push({ ...data[key], path: key });
        }
      }
    }
    setCategories(values);
  }, [isLoading, data]);

  return (
    <Box>
      <Box flex="1" textAlign="left" px={4} textStyle={'menuTitle'}> Submit a Report</Box>
      <ScaleFade initialScale={0.5} in={Boolean(data)}>
        <UnorderedList p="0" styleType="none" ml={0} tabIndex={0}>
          <ListItem _hover={{ background: 'fillColoursUiPrimary1Hover.500' }}>
            <Link
              py="0.5rem"
              px="1rem"
              w="100%"
              d="inline-block"
              _hover={{ background: 'fillColoursUiPrimary1Hover.500' }}
              breakout="true"
              onClick={() => {
                showBottomDrawer();
              }}
              aria-label="report issue"
              tabIndex={1}
              color={'fillColoursUiPrimary1.500'}
            >
              <Image
                src="/images/location_icon_generic.png"
                fallbackSrc="/images/location_icon_generic.png"
                alt="icon"
                w="2.5rem"
                h="2.5rem"
                my="0.5rem"
                ml="0.5rem"
                d="inline-block"
              />
              <Text p="2" textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'} d="inline">Click to Collab</Text>
            </Link>
          </ListItem>
        </UnorderedList>
      </ScaleFade>
      <Accordion border="0" allowMultiple defaultIndex={0} allowToggle>
        <AccordionItem border="0">
          <AccordionButton>
            <Box flex="1" textAlign="left" textStyle={'menuTitle'}> View on Map</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} px="0">
            {isLoading ?
              <Center bg="transparent" h="100px"><Spinner /></Center>
              :
              <ScaleFade initialScale={0.5} in={Boolean(data)}>
                <UnorderedList p="0" styleType="none" ml={0} tabIndex={0}>
                  {categories && categories.map((category) => <CategoryItem key={`${category.key}`} data={category} />)}
                </UnorderedList>
              </ScaleFade>
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>);
};

export interface CategoryProps {
  data: Category;
}

const CategoryItem: React.FC<CategoryProps> = ({ data }) => {
  const { showCategory, centerLocation } = useMapActions();
  const { showNone } = useModalActions();
  const { colorMode } = useColorMode();
  console.log({ data });
  return (
    <ListItem _hover={{ background: 'fillColoursUiPrimary1Hover.500' }}>
      <Link
        tabIndex={0}
        py="0.5rem"
        px="1rem"
        w="100%"
        d="inline-block"
        _hover={{ background: 'fillColoursUiPrimary1Hover.500' }}
        onClick={() => { showCategory(data); showNone(); centerLocation({ zoom: [10] }); }}
      >
        <Image
          src={data.menu_icon}
          alt="icon"
          w="2.5rem"
          h="2.5rem"
          my="0.5rem"
          ml="0.5rem"
          d="inline-block"
        /><Text d="inline" p="4" textStyle={colorMode === 'light' ? 'linkLight' : 'linkDark'}>{data.title}</Text>
      </Link>

    </ListItem>);
};
export default CategoryList;
