import 'mapbox-gl/dist/mapbox-gl.css';

import { MapMouseEvent } from 'mapbox-gl';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
// import { FaMapMarkerAlt } from 'react-icons/fa';
import { MapContext, Marker, RotationControl, ZoomControl } from 'react-mapbox-gl';
import { useQuery } from 'react-query';

import {
  Box,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  ScaleFade,
  Skeleton,
  Spinner,
  chakra,
  useColorMode,
  useToast,
} from '@chakra-ui/react';

import { fetchLayer } from '../../api/civilAPI';
import { convertTow3w } from '../../api/what3wordsAPI';
import { useMapContext, useMapState } from '../../context/mapContext';
import { useModalActions } from '../../context/modalContext';
import { Coordinate, MapEvent } from '../../types';
import useMapBox from './hooks/useMapBox';

interface MarkerProps {
  coordinates: Coordinate;
  details?: any;
  icon?: string;
}
interface SelectorProps {
  coordinates: Coordinate | null;
}
const Map: React.FC = () => {
  // const { selectLocation,setWords } = useContext(CivilActions);
  // const { selectedLocation } = useContext(MapContext);
  const { colorMode } = useColorMode();
  const { setWords,
    selectLocation,
    selectedLocation,
    currentLocation,
    center: viewCenter,
  } = useMapContext();
  const { GeolocateControl, MapBox, hasLoaded,
    // convertTow3w
  } = useMapBox();
  const [center, setCenter] = useState<Coordinate>(currentLocation || viewCenter.coord);
  const [zoom, setZoom] = useState<[number]>([15]);
  const [markerLocation, setMarkerLocation] = useState<Coordinate | null>(null);
  const toast = useToast();

  const toasted = React.useRef<boolean>(false);
  const newLocation = useCallback(
    async (_, e) => {
      const coordinate: Coordinate | null = e.lngLat ? [e.lngLat.lng, e.lngLat.lat] : null;
      selectLocation(coordinate);
      if (coordinate) {
        const words = await convertTow3w(coordinate);
        setWords(words);
      }
    }
    , [selectLocation, setWords],
  );

  /**
   * Handles the MapClick Event drops a pin, saves location and zooms to pin
   * @param map The target map
   * @param e MapMouseEvent form MapboxGl
   */
  const onMapClick: MapEvent<MapMouseEvent> = async (map, e) => {
    if (!toasted.current) {
      toast({
        title: 'You’ve dropped your 1st pin!',
        description: ' Now tap the pin again and let’s get started',
        status: 'info',
        duration: 10000,
        isClosable: true,
        position: 'bottom',
      });
      toasted.current = true;
    }

    const coordinate: Coordinate | null = e && e.lngLat ? [e.lngLat.lng, e.lngLat.lat] : null;
    if (coordinate) {
      await Promise.all([map.flyTo({ center: coordinate, zoom: 15 }), newLocation(map, e)]);
      setTimeout(() => {
        setMarkerLocation(coordinate);
      }, zoom[0] !== 15 ? 1800 : 0);
    }
  };

  const onMove: MapEvent<MapMouseEvent> = useCallback((map) => {
    const { lng, lat } = map.getCenter();

    setMarkerLocation((state) => {
      if (state !== null) {
        return [lng, lat];
      } else {
        return null;
      }
    });
  }, []);

  useEffect(() => {
    let timeOut: any;
    if (selectedLocation) {
      setCenter(selectedLocation);
      setZoom([15]);
      timeOut = setTimeout(() => {
        setMarkerLocation(selectedLocation);
      }, 1800);
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [selectedLocation]);

  /**
   * General Center View
   */
  useEffect(() => {
    if (viewCenter) {
      setCenter(viewCenter.coord);
      setZoom(viewCenter.zoom);
      console.log({ viewCenter });
    }
  }, [viewCenter]);

  const initial = useRef(true);
  /**
   * Current Location Initial Center
   */
  useEffect(() => {
    if (currentLocation && initial.current) {
      setCenter(currentLocation);
      setZoom([15]);
      console.log({ currentLocation, initial });
      initial.current = false;
    }
  }, [currentLocation]);

  return (
    <Box>
      <Skeleton isLoaded={hasLoaded}>
        {MapBox
        && (
        <MapBox
          style={colorMode === 'light' ? 'mapbox://styles/mapbox/streets-v11' : 'mapbox://styles/mapbox/dark-v10'}
          containerStyle={{
            height: 'calc(100vh - 56px)',
            width: '100vw',
            position: 'absolute',
            top: '56px',
          }}
          zoom={zoom}
          center={center}
          onClick={onMapClick}
          onMove={onMove}
          // renderChildrenInPotal
        >

          <NewMarker coordinates={markerLocation} />

          <CategoryMarkers />
          <ZoomControl position="bottom-right" />
          {/* <ScaleControl position="bottom-left" /> */}
          <RotationControl position="bottom-right" />
          <MapContext.Consumer>
            {GeolocateControl}
          </MapContext.Consumer>
        </MapBox>
        )}
      </Skeleton>
    </Box>);
};

const NewMarker: React.FC<SelectorProps> = ({ coordinates }) => {
  const { showBottomDrawer } = useModalActions();
  const toast = useToast();
  useEffect(() => {
    if (!window.localStorage.getItem('welcome')) {
      toast({
        title: 'Welcome To BeepCollab!',
        description: 'Tap on the map to Drop A Pin & Let’s Collab by Category',
        status: 'info',
        duration: 10000,
        isClosable: true,
        position: 'top',
      });
      window.localStorage.setItem('welcome', 'true');
    }
  }, [toast]);
  const onClick = () => {
    showBottomDrawer();
  };
  return coordinates === null ? null : (
    <Marker
      onClick={onClick}
      coordinates={coordinates}
      anchor="bottom"
    >
      <ScaleFade initialScale={0.8} in={Boolean(coordinates)}>
        <IconButton
          color="niceBlue.500"
          fontSize="3.75rem"
          h="3.5rem"
          w="3.5rem"
          maxW="3.5rem"
          justifyContent="flex-start"
          bg="transparent"
          _hover={{ background: 'transparent' }}
          aria-label="Select Location"
          icon={<Image w="100%" maxW="12rem" src="/images/location_icon_generic.png" alt="BeepBus" data-testid="cts-civil-logo" />}
        />

      </ScaleFade>
    </Marker>);
};

const CategoryMarker: React.FC<MarkerProps> = ({ coordinates, details, icon }) => {
  // const { setDetails, showSidebar } = useModalActions();
  const [zIndex, setzIndex] = useState(3);
  const onPressMarker = () => {
    // e.stopPropagation();
    // setDetails(details);
    // showSidebar();
    setzIndex(4);
    console.log({ details });
  };

  return (
    <Marker
      coordinates={coordinates}
      anchor="bottom"
      style={{ zIndex }}
    >
      <Popover onClose={() => {
        setzIndex(3);
      }}
      >
        <PopoverTrigger>
          <Image boxSize="60px" src={icon} onClick={onPressMarker} sx={{ zIndex: '-1' }} />
        </PopoverTrigger>
        <PopoverContent sx={{ position: 'relative', width: '150px', left: '-45px', zIndex: '1000' }}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Confirmation!</PopoverHeader>
          <PopoverBody>{details.issueId }</PopoverBody>
          <PopoverBody>{details.name }</PopoverBody>
          <PopoverBody>{details.words }</PopoverBody>
        </PopoverContent>
      </Popover>
    </Marker>

  );
};

const CategoryMarkers = () => {
  const { category } = useMapState();
  const mode: any = category.path || '';
  const { isLoading, isError, data } = useQuery(`layer-${mode}`, fetchLayer(mode), { retry: false });
  if (category.path && data && data.features) {
    return (
      <> { data?.features.map((feature, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CategoryMarker key={`${mode}-${index}`} coordinates={feature.geometry.coordinates} details={feature.properties} icon={category.icon} />
      ))
      }
      </>);
  } else if (isLoading && !isError) {
    return <Spinner />;
  }
  return null;
};

export default chakra(React.memo(Map));
