import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { fetchCategoryList } from '../../../api/civilAPI';
import { Category } from '../../../types';

export default function useCategories() {
  const [categories, setCategories] = useState<Category[] | null>(null);
  const { isLoading: listLoading, data: list } = useQuery('category-list', fetchCategoryList);
  useEffect(() => {
    const values: any[] = [];
    if (list) {
      for (const path in list) {
        if (path) {
          values.push({ ...list[path], path });
        }
      }
    } else {
      values.concat([
        {
          key: 'HYDRANT',
          title: 'Fire Hydrant Location',
          description: 'Location of local fire hydrant, used to assist the fire service in case of emergencies',
          type: 'information',
          icon: '/images/category_icon_hydrant.png',
          path: 'hydrants',
        },
        {
          key: 'PIPE',
          title: 'Burst Pipe',
          description: 'Location of burst water pipe, used to assist the water authority.',
          type: 'issue',
          icon: '/images/category_icon_pipe.png',
          path: 'burstPipes',
        },
        {
          key: 'POTHOLE',
          title: 'Pot Hole',
          description: 'Location of damaged road',
          type: 'issue',
          icon: '/images/category_icon_pothole.png',
          path: 'potholes',
        },
      ]);
    }
    setCategories(values);
  }, [listLoading, list]);
  return { categories };
}
